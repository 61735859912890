// 案件详情
<template>
  <div>
    <el-row :gutter="15" style="min-height:calc(100vh - 130px)" class="flex">
      <el-col :span="16">
        <el-card style="height:100%" class="pr">
          <div class="detail-tab mt-30">
        <span class="cur" :class="tabindex==0? 'active':''" @click="detailTab(0)">客户信息</span>
        <span class="cur" :class="tabindex==1? 'active':''" @click="detailTab(1)">合同信息</span>
        <span class="cur" :class="tabindex==2? 'active':''" @click="detailTab(2)">案件信息</span>
        <span v-show="check_status>=4" class="cur" :class="tabindex==3? 'active':''" @click="detailTab(3)">交单信息</span>
        <span class="cur" :class="tabindex==4? 'active':''" @click="detailTab(4)">人员信息</span>
        <span class="cur" :class="tabindex==5? 'active':''" @click="detailTab(5)">提案信息</span>
        <span class="cur" :class="tabindex==6? 'active':''" @click="detailTab(6)">官方文件</span>
        <span class="cur" :class="tabindex==7? 'active':''" @click="detailTab(7)">黄票收据</span>
          </div>
          <!-- 客户信息 -->
          <div v-show="tabindex==0" class="p30">
            <div class="bd">客户信息</div>
            <table class="f12 mt-20">
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>客户类型</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.client_type==1?'企业':cient.client_type==2?'个人':cient.client_type==3?'院校':cient.client_type==4?'研究院':'其他'}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>客户名称</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.client_name}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span v-if='cient.client_type==2'>身份证</span>
                      <span v-else>商业代码</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.client_card}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>联系电话</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.client_phone}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>通讯地址</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.province?cient.province.name:''
                        }}{{cient.city?cient.city.name:''}}{{cient.area?cient.area:''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>详细地址</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.client_address}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>联系人</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.contact_name}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>联系人电话</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.contact_phone}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>客户邮箱</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{cient.client_email}}</span>
                    </div>
                    <!-- <div class="btd flex-jc-alc p15-30">
                      <span>成立时间</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span></span>
                    </div> -->
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <!-- 合同信息 -->
          <div v-show="tabindex==1" class="p30">
            <div class="bd">合同信息</div>
            <table class="f12 mt-20">
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>合同方式</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.category==0?'合同':contractData.category==1?'电子合同':contractData.category==2?'纸质合同'
                        :contractData.category==3?'无合同':contractData.category==4?'长期协议':''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>付款方式</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.contract_pay_type==1?'全额付款':contractData.contract_pay_type==2?'分两期付款':contractData.contract_pay_type==3?'分三期付款':''}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>业务类型</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.servicetype?contractData.servicetype.serviceType_name:''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>具体业务</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.configService?contractData.configService.service_name:''}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>是否包授权</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.is_empower==0?'否':contractData.is_empower==1?'是':''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>是否开收据</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.receipt_type==0?'否':contractData.receipt_type==1?'是':''}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>盖章公司</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.company?contractData.company.company_name:''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>附加合同</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.contract_add_nums}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>合同编号</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.contract_code}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>案件数量</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.num}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>合同金额</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{contractData.contract_price}}</span>
                    </div>
                    <!-- <div class="btd flex-jc-alc p15-30">
                      <span>贡献金额</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>8500</span>
                    </div> -->
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <!-- 案件信息 -->
          <div v-show="tabindex==2" class="p30">
            <div class="bd">案件信息</div>
            <table class="f12 mt-20">
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>是否双报</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                       
                      <span>{{patentData.shuangbao_type==1?"否":patentData.shuangbao_type==2?"是":""}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>案件类型</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>专利：{{patentData.configService?patentData.configService.service_name:''}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>领域</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.subjectLibraryType?patentData.subjectLibraryType.name:''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>题目</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.name}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>是否加急</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.is_urgent==0?'否':patentData.is_urgent==1?'是':''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>材料情况</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span></span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>是否费减</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.price_not==1?'否':patentData.price_not==2?'是':''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>案件模式</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.model_type==1?'仅代写':patentData.model_type==2?'仅代交':patentData.model_type==3?'代写代交':''}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>案件成本</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span></span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>申请类型</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.is_reject==0?"新申请":patentData.is_reject==1?"驳回重写":''}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>案件状态</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{
                       patentData.check_status==-2?'作废':
                       patentData.check_status==-1?'不确定':
                       patentData.check_status==0?'合同审核中':
                       patentData.check_status==1?'待分配':
                       patentData.check_status==2?'撰写中':
                       patentData.check_status==3?'待交单':
                       patentData.check_status==4?'待确认':
                       patentData.check_status==5?'待修改':
                       patentData.check_status==6?'待制作':
                       patentData.check_status==7?'待转档':
                       patentData.check_status==8?'待推送':
                       patentData.check_status==9?'已推送':
                       patentData.check_status==10?'已完结':
                       patentData.check_status==11?'已驳回':
                       patentData.check_status==12?'待答复':
                       patentData.check_status==13?'已受理':
                       patentData.check_status==14?'已答复':
                       patentData.check_status==15?'主动提交':
                       patentData.check_status==16?'待答复审核不通过':
                       patentData.check_status==17?'主动提交待审核':
                       patentData.check_status==18?'主动提交审核不通过':
                       patentData.check_status==19?'待答复待审核':
                       patentData.check_status==20?'流程主管待审核':
                       patentData.check_status==21?'中间文件流程处理':
                       patentData.check_status==22?'答复流程处理':
                       patentData.check_status==23?'中间件流程主管处理':
                       patentData.check_status==24?'答复流程主管处理':
                       patentData.check_status==25?'流程驳回(已退回)':
                       patentData.check_status==26?'(复审)质检待审核':
                       patentData.check_status==27?'(复审)质检不通过':
                       patentData.check_status==28?'(复审)质检通过(流程待制作)':
                       patentData.check_status==29?'(复审)流程主管待审核':
                       patentData.check_status==30?'(复审)流程主管审核不通过':
                       patentData.check_status==31?'(复审)流程主管通过(待提交)':
                       patentData.check_status==32?'已推送':""
                        
                        }}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>是否立即分配</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.allot_type==1?'是':'否'}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>专利号</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.patent_code}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>CPC状态</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.patent_status}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <!-- <div class="btd flex-jc-alc p15-30">
                      <span>申请类型</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>新申请</span>
                    </div> -->
                    <div class="btd flex-jc-alc p15-30">
                      <span>合同金额包含</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{patentData.contract_bh}}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <!-- 交单信息 -->
          <div v-show="tabindex==3" class="p30">
            <div class="bd" v-if="applicant.length>0">申请人</div>
            <table class="f12 mt-20" v-for="(item,index) in applicant"  :key="item.id">
              <tr>
                <td >
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>第{{index+1}}申请人姓名</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_applicant.applicant_name}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>身份证/商业代码</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_applicant.card_number}} </span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>通讯地址</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_applicant.province?item.client_applicant.province.name:''
                        }}{{item.client_applicant.city?item.client_applicant.city.name:''}}{{item.client_applicant.area?item.client_applicant.area:''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>详细地址</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_applicant.address}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>备案号码</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_applicant.patent_code}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>邮编</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_applicant.postcode}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>申请人类型</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                          
                      <span>{{item.client_applicant.apply_type}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>是否费减</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.price_out}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>联系电话</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_applicant.phone}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>邮箱</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_applicant.email}}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div v-if="inventor.length>0" class="bd mt-30">发明人</div>
            <table class="f12 mt-20" v-for="(item,index) in inventor"  :key="item.id">
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>第{{index+1}}发明人</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_inventor.inventor_name}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>国籍</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_inventor.nationality}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>证件类型</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_inventor.card_type==1?'身份证':item.client_inventor.card_type==2?'护照':''}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>证件号</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{item.client_inventor.card_num}}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div v-show="tabindex==4" class="p30">
            <div class="bd">商务信息</div>
            <table class="f12 mt-20">
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>商务姓名</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{flowPathInfo.realname}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>联系电话</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{flowPathInfo.phone}}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>所属分公司</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{flowPathInfo.company_name}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>所属部门</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{flowPathInfo.department_name}}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <!-- <div class="bd mt-30">代理人信息</div>
            <table class="f12 mt-20">
              <tr>
                <td>
                  <div class="flex">
                    <div class="btd flex-jc-alc p15-30">
                      <span>代理人姓名</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{agentInfo.realname}}</span>
                    </div>
                    <div class="btd flex-jc-alc p15-30">
                      <span>代理人电话</span>
                    </div>
                    <div class="f1 flex-al p15-30">
                      <span>{{agentInfo.phone}}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table> -->
          </div>
          <!-- 提案信息 -->
          <div v-show="tabindex==5" class="p30">
            <div class="bd">提案信息</div>
            <el-table
              :data="fileList"
              class="mt-20 f12"
              border
              :header-cell-style="{
              color: '#222530',
              fontSize: '14px',
              backgroundColor: '#eaf1ff',
              borderColor: '#ccc',
            }"
            >
              <el-table-column prop="file_desc" label="文件名称" align="center"></el-table-column>
                
              <el-table-column  label="文件状态" align="center">
                 <template slot-scope="scope">{{
                   scope.row.send_type==1?'未同步':scope.row.send_type==2?'已同步':scope.row.send_type==3?'中间件未同步':
                   scope.row.send_type==4?'中间件已同步':scope.row.send_type==5?'复审文件未同步':scope.row.send_type==6?'复审文件已同步':''
                   }}</template>
              </el-table-column>
              <el-table-column prop="addtime" label="添加时间	" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                 <template slot-scope="scope">
                   <a class="mr-20" :href="scope.row.file_path">下载</a>
                  <span class="cur" @click="yulan(scope.row.file_path)">预览</span>     
                 </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 官方文件 -->
          <div v-show="tabindex==6" class="p30">
            <div class="bd t-c">官方文件</div>
            <el-table
              :data="tongData"
              class="mt-20 f12"
              border
              :header-cell-style="{
              color: '#222530',
              fontSize: '14px',
              backgroundColor: '#eaf1ff',
              borderColor: '#ccc',
            }"
            >
              <el-table-column prop="tongzhishumc" label="文件名称" align="center"></el-table-column>
              <el-table-column prop="shenqingbh" label="申请编号" align="center"></el-table-column>
              <el-table-column prop="tongzhisbh" label="通知书编号" align="center"></el-table-column>
              <el-table-column prop="fawenrq" label="发文日期" align="center"></el-table-column>
              <el-table-column prop="addres" label="操作" align="center">
                <template slot-scope="scope">
                   <a v-if="scope.row.file_path[0]" :href="scope.row.file_path[0]">下载</a>
                 </template>
                
              </el-table-column>
            </el-table>
          </div>

          <!-- 黄票信息 -->
          <div v-show="tabindex==7" class="p30">
             <el-table
                :data="hp"
                border
                :header-cell-style="{color:'#222530', fontSize: '14px', backgroundColor: '#eaf1ff',borderColor: '#ccc'}"
                style="font-size: 12px; margin-top:12px;"
              >
                <el-table-column prop="name" label="名称" align="center"></el-table-column>
                <el-table-column prop="addtime" label="时间" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                   <a v-if="scope.row.path" :href="scope.row.path">下载</a>
                 </template>
                </el-table-column>
              </el-table>
            <!-- <div class="bd t-c">黄票信息</div>
            <div style="padding:0 50px" class="mt-30">
              <div class="f18 blue">发票</div>
              <el-table
                :data="invoice"
                border
                :header-cell-style="{color:'#222530', fontSize: '14px', backgroundColor: '#eaf1ff',borderColor: '#ccc'}"
                style="font-size: 12px; margin-top:12px;"
              >
                <el-table-column prop="sum" label="发票张数" align="center"></el-table-column>
                <el-table-column prop="amount" label="金额" align="center"></el-table-column>
                <el-table-column prop="type1" label="发票类型" align="center"></el-table-column>
                <el-table-column prop="type2" label="开票类型" align="center"></el-table-column>
                <el-table-column label="操作" align="center"></el-table-column>
              </el-table>
            </div> -->
            <!-- <div style="padding:0 50px" class="mt-30">
              <div class="f18 blue">黄票</div>
              <el-table
                :data="invoice"
                border
                :header-cell-style="{color:'#222530', fontSize: '14px', backgroundColor: '#eaf1ff',borderColor: '#ccc'}"
                style="font-size: 10px; margin-top:12px;"
              >
                <el-table-column prop="sum" label="发票张数" align="center"></el-table-column>
                <el-table-column prop="amount" label="金额" align="center"></el-table-column>
                <el-table-column prop="type1" label="发票类型" align="center"></el-table-column>
                <el-table-column prop="type2" label="开票类型" align="center"></el-table-column>
                <el-table-column label="操作" align="center"></el-table-column>
              </el-table>
            </div> -->
          </div>
          <!-- 时效信息
          <div v-show="tabindex==7" class="p30">
            <div class="flex-j-c" style="width:100%">
              <el-steps direction="vertical" :active="1" space='100px'>
              <el-step  title="步骤 1"></el-step>
              <el-step  title="步骤 2"></el-step>
              <el-step  title="步骤 3" description="这是一段很长很长很长的描述性文字"></el-step>
            </el-steps>
            </div>
          </div>-->
          <div class="flex-j-c mt-30">
            <div v-if='check_status==4&&tabindex==5' @click="dialogVisible=true" class="submit mr-20">确认提案</div>
             <div class="submit" @click="$router.go(-1)">返回</div>
          </div> 
           
        </el-card>
      </el-col>
      <el-col :span="8">
        <div style="height:100%" class="gd">
          <monitor></monitor>
          <infor style="flex:1"></infor>
        </div>
      </el-col>
    </el-row>
    <el-dialog
    center
  title="提案信息"
  :visible.sync="dialogVisible"
  width="400px"
  >
  <div class="p30"><span class="mr-30">请确认提案:</span> <el-radio v-model="status" label="0">不通过</el-radio>
  <el-radio class="ml-30" v-model="status" label="1">通过</el-radio></div>
  <div class="flex" style="padding-left:30px">
   <span class="mr-30">不通过描述:</span> <textarea style="width: 200px; height: 100px;outline:0" v-model="confirm_remark"></textarea>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="draft">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>
<script>
import monitor from "./common/monitor";
import infor from "./common/Infor";
export default {
  components: {
    monitor,
    infor
  },
  created() {
    this.patent_id=this.$route.query.patent_id||''
    this.contract_id=this.$route.query.contract_id||''
    this.check_status=this.$route.query.check_status||''
   this.cientInfo()
  },
  data() {
    return {
      confirm_remark:'',//不通过描述
      cient:{},//客户信息
      dialogVisible:false,
      check_status:4,//案件状态
      status:'1',//提案通不通过
      patent_id:'',
      contract_id:'',
      tableData: [],
      contractData:{},//合同
      patentData:{},//案件信息
      applicant:[{client_applicant:{}}],//申请人
      inventor:[{client_inventor:{}},{client_inventor:{}}],//发明人
      fileList:[],//提案信息
      tongData:[],
      invoice: [
        { sum: 1, amount: 2000.0, type1: "增值税发票", type2: "单位" },
        { sum: 1, amount: 2000.0, type1: "增值税发票", type2: "单位" }
      ],
      flowPathInfo:{},//商务信息
      agentInfo:{},//代理人信息
      hp:[],//黄票
      tabindex: 0,
      tabs: [
        "客户信息",
        "合同信息",
        "案件信息",
        "交单信息",
        "人员信息",
        "提案信息",
        "官方文件",
        "黄票收据"
      ],
    };
  },
  methods: {
    detailTab(index) {
      this.tabindex = index;
      switch(this.tabindex){
        case 0: this.cientInfo()
        break;
        case 1: this.contract()
        break;
        case 2: this.info()
        break;
         case 3: this.jdInfo()
        break;
         case 4: this. personalInfo()
        break;
         case 5: this.patentFileList()
        break;
         case 6: this.tongzhislist()
        break;
         case 7: this.hpInfo()
        break;
        }
    },
     // 客户信息
    cientInfo(){
      this.$request.get(`/client/member/cientInfo`).then(res=>{
       if(res.data.code==200){
         this.cient=res.data.data
       }
     })
    },
     // 合同信息
    contract(){
      this.$request.get(`/client/contract/info?contract_id=${this.contract_id}`).then(res=>{
        if(res.data.code==200){
          this.contractData=res.data.data||{}
        }
     })
    },
     // 案件信息
    info(){
      this.$request.get(`/client/patent/info?patent_id=${this.patent_id}`).then(res=>{
      if(res.data.code==200){
        let str=res.data.data.contract_bh||''
        let arr=[]
         if(str.indexOf(5)!==-1){
          res.data.data.contract_bh=''
          this.patentData=res.data.data
          return
         }
         if(str.indexOf(1)!==-1){
           arr.push('官费')
         }
        if(str.indexOf(2)!==-1){
           arr.push('首年年费')
         }
         if(str.indexOf(3)!==-1){
           arr.push('次年费客户缴纳')
         }
         if(str.indexOf(4)!==-1){
           arr.push('次年费公司缴纳')
         }
        res.data.data.contract_bh=arr.join()
        this.patentData=res.data.data

        
      }
     })
    },
    // 交单信息
    jdInfo(){
     this.$request.get(`/client/patent/jdInfo?patent_id=${this.patent_id}`).then(res=>{
       if(res.data.code==200){
        this.applicant=res.data.data.applicant
        this.inventor=res.data.data.inventor
       }
     })
    },
    // // 人员信息
   personalInfo(){
      this.$request.get(`/client/patent/personalInfo?patent_id=${this.patent_id}`).then(res=>{
       if(res.data.code==200){          
          this.flowPathInfo=res.data.data.flowPathInfo//商务信息
          this.agentInfo=res.data.data.agentInfo//代理人信息
       }
      
     })
    },
    // 提案信息
    patentFileList(){
      this.$request.get(`/client/patent/patentFileList?patent_id=${this.patent_id}`).then(res=>{
       if(res.data.code==200){
         this.fileList=res.data.data
       }
     })
    },
    // 官方文件
    tongzhislist(){
      this.$request.get(`/client/patent/tongzhislist?patent_id=${this.patent_id}`).then(res=>{
       if(res.data.code==200){
         this.tongData=res.data.data
       }
     })
    },
    // 黄票收据
    hpInfo(){
      this.$request.get(`/client/patent/hpInfo?patent_id=${this.patent_id}`).then(res=>{
       if(res.data.code==200){
           this.hp=res.data.data
       }
     })
    },
    //确认填
    draft(){
      let confirm_remark=this.status==0? this.confirm_remark:''
      this.$request.post('/client/patent/draftResolution',{patent_id:this.patent_id,status:this.status,confirm_remark,}).then(res=>{
        if(res.data.code==200){
        this.dialogVisible = false
        }
      })
      
    },
    yulan(url){
      let time1=new Date().getTime()
      let url1=`https://view.officeapps.live.com/op/view.aspx?src=${url}?time=${time1}`
      window.open(url1)

    }
  },
};
</script>

<style scoped>
.box-card {
  padding-bottom: 48px;
  flex-wrap: wrap;
}
.case-detail {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}
.detail-tab {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 30px;
}
.detail-tab span {
  display: inline-block;
  padding: 14px 20px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
}
.detail-tab span.active {
  background-color: #2962ff;
  border-radius: 6px 6px 0 0;
  color: #fff;
  border-bottom: 2px solid #2962ff;
}
.el-tag {
  cursor: pointer;
}
.type-tag {
  display: inline-block;
  background-color: #2962ff;
  height: 20px;
  color: #fff;
  border-radius: 5px;
  padding: 0 6px;
  line-height: 20px;
  width: 60px;
  box-sizing: border-box;
  text-align: center;
}
.gd {
  display: flex;
  flex-direction: column;
}
table {
  width: 100%;
  border-collapse: collapse;
}
table,
td {
  border: 1px solid #ccc;
}
.btd {
  background-color: #eaf1ff;
  width: 80px;
}
.f1 {
  flex: 1;
}
.p15-30 {
  padding: 15px;
}
.submit {
  width: 70px;
  height: 30px;
  opacity: 1;
  background: #2962ff;
  border-radius: 8px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
</style>

